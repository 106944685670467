<script>
import axios from 'axios'
import Edko from '../Edko.vue'
export default {
  props: ['lesson'],
  name: "vocabulary",
  components: { Edko },
  data() {
    return {
      words: [],
      opens:[],
      tab: 's',
      loading: false
    }
  },
  methods: {
    saveChanges() {

      if (this.lesson.id) {
        axios.put(`/oj/lesson/${this.lesson.id}/vocabulary.json`,
            { words: this.words,
                    lesson: this.lesson
            }).then(r => {

        })
      }else{
        axios.post(`/oj/lesson/vocabulary_words.json`,
            { words: this.words,
                    lesson: this.lesson
            }).then(r => {

              this.lesson.id = r.data.lesson.id
              this.loadVocabularyWords()
        })
      }
    },
    loadVocabularyWords() {
      if (!this.lesson || !this.lesson.id) return
      this.loading = true
      axios.get(`/oj/lesson/${this.lesson.id}/vocabulary.json`).then(r => {

        this.words = r.data.items
        for(let i = 0; i < this.words.length; i++){
          this.opens.push(false)
        }
        this.loading = false
      })
    },
    get_language_id(){
      if (this.words.length>0){
        return this.words[0].vwt[0].language_id
      }
      return 1
    },
    add_blank_word(){
      this.words.push({
        "vocabulary_id": this.lesson.id,
        source: '',
        prefix: '',
        postfix: '',
        avatar_text: '',
        extra_info: '',
        extra_info_type: '',
        alternatives: '',
        description: '',
        record: '',
        position: '',
        student_id: '',
        week_id: '',
        vwt: [
          {
            translation: '',
            description: '',
            alternatives: '',
            language_id: this.get_language_id(),
            vocabulary_word_id: 0
          }
        ]
      })
    }

  },

  mounted() {

    console.log('mounted  ww')
    this.loadVocabularyWords()
  },

  watch: {
    lesson() {
      this.loadVocabularyWords()
    },
  },
}

/*
* word: {
    "id": 679,
    "vocabulary_id": 1800,
    "source": "a fireman",
    "target": null,
    "prefix": null,
    "postfix": null,
    "avatar_text": null,
    "extra_info": null,
    "extra_info_type": null,
    "alternatives": null,
    "description": null,
    "record": null,
    "position": null,
    "student_id": null,
    "week_id": null,
    "vocabulary_word_translations": [
      {
        "id": 574,
        "translation": "hasič",
        "description": null,
        "alternatives": null,
        "language_id": 1,
        "vocabulary_word_id": 679,
        "tmp_alternatives": "hasič, požárník, hasičský důstojník",
        "ai_process_status": "ai_created",
        "tmp_description": "a person who extinguishes fires and saves lives",
        "translator_event_id": 45,
        "tmp_source_alternatives": "firefighter, fire officer"
      }
    ]
  },
*
* */

</script>

<template>
  <div>

    <ul class="nav nav-tabs">
      <li :class="tab === 's' ? 'active':''"><a data-toggle="tab" href="#"
                                                @click.prevent="tab='s'">Slovíčka</a></li>
      <li :class="tab === 'options' ? 'active':''"><a data-toggle="tab" href="#"
                                                      @click.prevent="tab='options'">Základní nastavení</a></li>

    </ul>

<div v-if="tab=='options'">

      <div class="control-group"><label for="name" class="control-label required">Název obsahu</label>
        <div class="controls">
          <input type="text" name="name" id="name" v-model="lesson.name" class="input-xxlarge">
          <p class="help-block" style="color: #999;">Stručný název.</p>
        </div>
      </div>

    <div class="control-group">
      <label for="secondary_name" class="control-label optional">Sekundární název obsahu</label>
      <div class="controls">
          <input type="text" name="secondary_name" id="secondary_name" v-model="lesson.secondary_name" class="input-xxlarge">
          <p class="help-block" style="color: #999;">Sekundární název - slouží zejména pro název ve vyučovaném jazyce</p>
      </div>
    </div>

      <div class="control-group">
        <label for="description" class="control-label optional">Text</label>
        <div class="controls">
            <Edko v-model="lesson.vocabulary.description" />
            <p class="help-block" style="color: #999;">Text obsahu.</p>
        </div>
      </div>




    <div class="control-group">
      <label for="transcript" class="control-label optional">Přepis nahrávky</label>
        <div class="controls">
          <textarea v-model="lesson.transcript" id="transcript" cols="40" rows="8" class="input-xxlarge"></textarea>
          <p class="help-block" style="color: #999;">Text, který si může student přečíst, když například nerozumí nahrávce</p>
        </div>
    </div>

      <div class="control-group">
        <label for="is_active" class="control-label optional">Je obsah určen pro použití?</label>
        <div class="controls">

          <input type="checkbox" name="is_active" id="is_active" v-model="lesson.is_active">
          <p class="help-block" style="color: #999;">Určuje, zda bude obsah zobrazen ve výpisu kurzu.</p>
        </div>
      </div>

    <div class="control-group">
      <label for="trialOnly" class="control-label optional">Je obsah určen pouze pro TRIAL kurzu?</label>
        <div class="controls">

          <input type="checkbox" name="trialOnly" id="trialOnly" v-model="lesson.trial_only">
          <p class="help-block" style="color: #999;">Obsah bude zobrazen pouze v trialu kurzu.</p>
        </div>
      </div>


    <div class="control-group"><label for="isNew" class="control-label optional">Slovíčka jsou nová</label>
        <div class="controls">

          <input type="checkbox" name="isNew" id="isNew" value="1" v-model="lesson.is_new">
          <p class="help-block" style="color: #999;">tak bude označen štítkem i ve studovně.</p>
        </div>
      </div>




</div>



<fieldset id="fieldset-word"  v-if="tab=='s'">


  <div v-if="loading" class="text-center">
    <img :src="`/img/1495.gif`" />
  </div>

    <div :class="`vocabulary-word-row ${opens[i] == true ? '': 'closed'}`" v-for="(word,i) in words" >

      <label for="word-0-vocabulary_prefix" class="optional">Slovíčko: {{word.source}} | ID: {{word.id}}</label>

      <input type="text" name="word[0][vocabulary_prefix]" v-model="word.prefix"
             class="prefix-input" data-id="23" data-group="word-0"
             placeholder="členy" data-original-title="Členy"
             data-content="Zobrazí se před slovíčkem. Je vhodný např. pro členy (der, die, das)."
             data-trigger="hover" data-placement="top" rel="popover">

      <input type="text" name="word[0][vocabulary_source]"
             v-model="word.source" class="source-input" data-id="23" data-group="word-0" placeholder="slovíčko">

      <input type="text" v-model="word.postfix" class="postfix-input" data-id="23" data-group="word-0"
             placeholder="postfix" data-original-title="Postfix"
             data-content="Zobrazí se za slovíčkem méně výrazně. Je vhodný např. pro koncovky."
             data-trigger="hover" data-placement="top" rel="popover">

      <input type="text"
             v-model="word.alternatives" class="source-alternatives-input" data-id="23" data-group="word-0"
             placeholder="alternativy slovíčka">
      <label for="word-0-vocabulary_description" class="optional">Výklad</label>

      <input type="text"  v-model="word.description"
             class="description-input" data-id="23" data-group="word-0" placeholder="výklad v cizím jazyce"
             data-original-title="Výklad" data-content="Slouží pro upřesnění významu v cizím jazyce."
             data-trigger="hover" data-placement="top" rel="popover">

      <label for="word-0-vocabulary_extrainfotype" class="optional">Doplňující informace</label>

      <select
              v-model="word.extra_info_type"
              class="extra-info-type-input"
              data-id="23" data-group="word-0"
              data-original-title="Typ doplňující informace"
              data-content="Zobrazí se kurzívou, např. &quot;Pl.&quot;, &quot;Fem.&quot;."
              data-trigger="hover" data-placement="top" rel="popover">
        <option value="0" label="" selected="selected"></option>
        <option value="1" label="Sg.">Sg.</option>
        <option value="2" label="Pl.">Pl.</option>
        <option value="3" label="Gender">Gender</option>
        <option value="4" label="Fem.">Fem.</option>
        <option value="5" label="Irreg.">Irreg.</option>
        <option value="6" label="BrE">BrE</option>
        <option value="7" label="AmE">AmE</option>
      </select>

      <input type="text"
             v-model="word.extra_info"
             class="extra-info-input" data-id="23" data-group="word-0" placeholder="nepravidelný tvar, singulár, plurál, pády..."
             data-original-title="Nepravidelný tvar, singulár, plurál, pády..."
             data-content="Slouží pro plurál, ženskou formu apod."
             data-trigger="hover" data-placement="top" rel="popover">

      <input type="text" v-model="word.avatar_text"
             class="avatar-text-input" data-id="23" data-group="word-0"
             placeholder="text pro automatický poslech - výslovnost"
             data-original-title="Text pro automatický poslech - výslovnost"
             data-content="Nikde se nezobrazí. Je-li vyplněn, použije se pro TTS místo základního tvaru slova."
             data-trigger="hover"
             data-placement="top" rel="popover">


      <tamplate  v-for="tran in word.vwt">

        <label for="word-0-translate_translation_cs" class="optional">Překlad cs</label>

        <input type="text" v-model="tran.translation"
               class="translation-input" data-id="23" data-group="word-0" placeholder="překlad">

        <input type="text" v-model="tran.description"
                class="translation-description-input" data-id="23" data-group="word-0"
               placeholder="doplňující vysvětlení významu" data-original-title="Doplňující vysvětlení významu"
               data-content="Slouží pro vysvětlení významu." data-trigger="hover" data-placement="top" rel="popover">

        <input type="text" v-model="tran.alternatives"
               class="alternatives-input" data-id="23" data-group="word-0" placeholder="povolené alternativy"
             data-original-title="Povolené alternativy"
               data-content="Seznam slovíček (oddělených středníkem), které jsou akceptovatelné při trénování."
               data-trigger="hover" data-placement="top" rel="popover">
      </tamplate>

      <a class="btn btn-default vocabulary-word-detail" @click="opens[i]=true" v-if="!opens[i]">Detail</a>
      <a class="btn btn-default vocabulary-word-hide" v-if="opens[i]" @click="opens[i]=false">Zavřít</a>
      <input type="submit" name="word[0][vocabulary_delete]" id="word-0-vocabulary_delete" value="Odstranit" data-id="23" data-group="word-0"
             class="btn btn-danger contentedit-delete">
      <br data-group="word-0">
    </div>

  <input type="submit"
         @click="add_blank_word"
         id="word-11-add"
         value="Přidat další"
         course="9"
         last-group="word-11"
         class="btn btn-success contentedit-add"
         style="float: left; width: 100%; max-width: 950px;">


</fieldset>

    <div class="form-actions">
      <input type="submit"
             name="submit"
             id="submitbutton"
             value="Uložit"
             class="btn btn btn-primary"
             @click.prevent="saveChanges"
      />
    </div>




    <pre>
      {{words}}
    </pre>
  </div>
</template>

<style scoped>
.btn-default{
  display: inline-block;
  *display: inline;
  padding: 4px 14px;
  margin-bottom: 0;
  *margin-left: .3em;
  font-size: 14px;
  line-height: 20px;
  *line-height: 20px;
  color: #333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255,255,255,0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;
}
</style>