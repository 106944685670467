<script>
import axios from "axios";
import {toast} from "vue3-toastify";
import Edko from "@/components/oj/Edko.vue";
export default {
name: "course-options" ,
  props:['course'],
  data(){
    return {
      co: this.course,
      kke:' ahoj'
    }
  },
  components:{Edko},
  methods:{
    tamtenInput(e){
      console.log('tamtenInput', e)
    },
    updateCourse(){
      axios.put(`/oj/courses/${this.course.id}`,{
        course: this.co
      }).then(()=>{
        toast.success('Course updated')
        this.$emit('course-updated')
      }).catch((e)=>{
        console.log(e.response.data)
        toast.error('Error updating course '+ e.response.data.errors.join('; '))
      })
    },
    is_new_course(){
      return this.course.id==null
    }

  },

  mounted() {
    this.co = this.course
  },
  watch:{
    course(){
      this.co = this.course
    }
  }

}
</script>

<template>

  <div class="control-group"><label for="name" class="control-label required">Název kurzu</label>
    <div class="controls">
      <input type="text" name="name" id="name" v-model="co.name" class="input-xxlarge">
    </div>
  </div>

  <div class="control-group"><label for="shortName" class="control-label required">Zkratka kurzu</label>
    <div class="controls">
      <input type="text" name="shortName" id="shortName" v-model="co.short_name" class="input-xxlarge">
    </div>
  </div>

  <div class="control-group"><label for="subTitle" class="control-label optional" v-tooltip="'This is a tooltip'">Podnadpis kurzu</label>
    <div class="controls">
      <VTooltip placement="right"><input type="text" name="subTitle" id="subTitle"
                                         v-model="co.subTitle"

                                         data-original-title="Podnadpis kurzu"
                                         data-content="Zobrazuje se v prezentačních výpisech kurzů - např. informace o průvodci kurzu, apod.)"
                                         data-trigger="click"
                                         rel="popover" class="input-xxlarge">
        <template #popper class="kamala">
          <h4>Podnadpis kurzu..</h4>
          Zobrazuje se v prezentačních výpisech kurzů - např. informace o průvodci kurzu, apod.)
        </template>
      </VTooltip>
    </div>
  </div>
  <div class="control-group"><label for="videoLectorName" class="control-label optional">Jméno video lektora</label>
    <div class="controls">
      <input type="text" name="videoLectorName" id="videoLectorName" v-model="co.video_lector_name" class="input-xxlarge">
    </div>
  </div>


  <div class="control-group"><label for="length" class="control-label optional">Délka kurzu</label>
    <div class="controls">
      <select name="length" id="length" v-model="co.length"
              data-original-title="Délka kurzu"
              data-content="Např. 6+1, kde jde o 7 měsíců (6 standardních, jeden zkouškový)"
              data-trigger="hover"
              rel="popover"
              :disabled="!is_new_course()">
        <option value="LENGTH_61M" label="LENGTH_61M" selected="selected">LENGTH_61M</option>
        <option value="LENGTH_31M" label="LENGTH_31M">LENGTH_31M</option>
        <option value="LENGTH_121M" label="LENGTH_121M">LENGTH_121M</option>
        <option value="BLANK" label="BLANK">BLANK</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="isNotLanguage" class="control-label optional">Jedná se o nejazykový kurz?</label>
    <div class="controls">
      <input type="hidden" name="isNotLanguage" value="0"><input type="checkbox" name="isNotLanguage" id="isNotLanguage" v-model="co.is_not_language">
    </div>
  </div>
  <div class="control-group"><label for="isOffline" class="control-label optional">Jedná se o offline kurz?</label>
    <div class="controls">
      <input type="hidden" name="isOffline" value="0"><input type="checkbox" name="isOffline" id="isOffline" v-model="co.is_offline">
    </div>
  </div>
  <div class="control-group"><label for="level" class="control-label optional">Úroveň kurzu</label>
    <div class="controls">
      <select name="level" id="level" :disabled="!is_new_course()" v-model="co.level">
        <option value="COURSE_LEVEL_A1" label="LEVEL_A1" selected="selected">LEVEL_A1</option>
        <option value="COURSE_LEVEL_A2" label="LEVEL_A2">LEVEL_A2</option>
        <option value="COURSE_LEVEL_B1M" label="LEVEL_B1M">LEVEL_B1M</option>
        <option value="COURSE_LEVEL_B1P" label="LEVEL_B1P">LEVEL_B1P</option>
        <option value="COURSE_LEVEL_B1" label="LEVEL_B1">LEVEL_B1</option>
        <option value="COURSE_LEVEL_B2M" label="LEVEL_B2M">LEVEL_B2M</option>
        <option value="COURSE_LEVEL_B2P" label="LEVEL_B2P">LEVEL_B2P</option>
        <option value="COURSE_LEVEL_B2" label="LEVEL_B2">LEVEL_B2</option>
        <option value="COURSE_LEVEL_C1" label="LEVEL_C1">LEVEL_C1</option>
        <option value="COURSE_LEVEL_C2" label="LEVEL_C2">LEVEL_C2</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="type" class="control-label optional">Jazyk</label>
    <div class="controls">
      <select name="type" id="type" :disabled="!is_new_course()" v-model="co.type">
        <option value="CZECH" label="CZECH">CZECH</option>
        <option value="ENGLISH" label="ENGLISH" selected="selected">ENGLISH</option>
        <option value="GERMAN" label="GERMAN">GERMAN</option>
        <option value="FRENCH" label="FRENCH">FRENCH</option>
        <option value="SPANISH" label="SPANISH">SPANISH</option>
        <option value="RUSSIAN" label="RUSSIAN">RUSSIAN</option>
        <option value="ITALIAN" label="ITALIAN">ITALIAN</option>
        <option value="ROMANIAN" label="ROMANIAN">ROMANIAN</option>
        <option value="BULGARIAN" label="BULGARIAN">BULGARIAN</option>
        <option value="POLISH" label="POLISH">POLISH</option>
        <option value="SLOVAK" label="SLOVAK">SLOVAK</option>
      </select>
    </div>
  </div>

  <div class="control-group"><label for="target" class="control-label optional">Jazyk studentů</label>
    <div class="controls">
      <select name="target" id="target" v-model="co.target"
              data-original-title="Jazyk studentů"
              data-content="Jazyk studentů kurzu. Pokud není vybrán, vybere se čeština."
              data-trigger="hover"
              rel="popover">
        <option value="0" label="-">-</option>
        <option value="CZECH" label="CZECH">CZECH</option>
        <option value="ENGLISH" label="ENGLISH">ENGLISH</option>
        <option value="GERMAN" label="GERMAN">GERMAN</option>
        <option value="FRENCH" label="FRENCH">FRENCH</option>
        <option value="SPANISH" label="SPANISH">SPANISH</option>
        <option value="RUSSIAN" label="RUSSIAN">RUSSIAN</option>
        <option value="ITALIAN" label="ITALIAN">ITALIAN</option>
        <option value="ROMANIAN" label="ROMANIAN">ROMANIAN</option>
        <option value="BULGARIAN" label="BULGARIAN">BULGARIAN</option>
        <option value="POLISH" label="POLISH">POLISH</option>
        <option value="SLOVAK" label="SLOVAK">SLOVAK</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="isDemo" class="control-label optional">Jedná se o demo kurz</label>
    <div class="controls">
      <input type="hidden" name="isDemo" value="0"><input type="checkbox"
                                                          name="isDemo"
                                                          id="isDemo"
                                                          v-model="co.is_demo"
                                                          data-original-title="Demo kurz"
                                                          data-content="Příznak, zda se jedná o demo kurz. Takový kurz slouží pro vyzkoušení zdarma a ne plnohodnotnou výuku."
                                                          data-trigger="hover" rel="popover">
    </div>
  </div>
  <div class="control-group"><label for="staticDemoLength" class="control-label optional">Délka TRIALu kurzu v týdnech</label>
    <div class="controls">
      <input type="text" name="staticDemoLength" id="staticDemoLength" v-model="co.static_demo_length"
             data-original-title="Délka TRIALu kurzu v týdnech"
             data-content="Kolik prvních týdnů z kurzu je studentovi povoleno vyzkoušet v rámci TRIALu"
             data-trigger="hover"
             rel="popover">
    </div>
  </div>

  <div class="control-group">
    <label for="demoLength" class="control-label optional">Délka demokurzu v procentách</label>
    <div class="controls">
      <input type="text" name="demoLength" id="demoLength" v-model="co.demoLength"
             data-original-title="Délka demokurzu v procentách"
             data-content="Kolik procent (0-100) kurzu je studentovi povoleno vyzkoušet"
             data-trigger="hover" rel="popover">
    </div>
  </div>
  <div class="control-group"><label for="chapter_type" class="control-label optional">Typ kapitoly</label>
    <div class="controls">
      <select name="chapter_type" v-model="co.chapter_type "   id="chapter_type" data-original-title="Typ kapitoly"
              data-content="Např. 3+1 týden (běžné týdny nemají Opakování), nebo 4+1 den (týden má jak Novou látku, tak Opakování)"
              data-trigger="hover" rel="popover" :disabled="!is_new_course()">
        <option value="TYPE_3PLUS1WEEK" label="TYPE_3PLUS1WEEK" selected="selected">TYPE_3PLUS1WEEK</option>
        <option value="TYPE_4PLUS1DAY" label="TYPE_4PLUS1DAY">TYPE_4PLUS1DAY</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="description" class="control-label optional">Popis kurzu</label>
    <div class="controls">
        <textarea name="description"
                  v-model="co.description"
                  id="description" cols="40" rows="8"
                  data-original-title="Popis kurzu"
                  data-content="Krátký, motivační popis kurzu."
                  data-trigger="hover"
                  rel="popover"
                  class="input-xxlarge"></textarea>
    </div>
  </div>
  <div class="control-group"><label for="guide" class="control-label required">Průvodce kurzem</label>
    <div class="controls">
      <select name="guide" id="guide"
              v-model="co.guide"
              data-original-title="Průvodce kurzem"
              data-content="Dle vybraného průvodce se studentům zobrazují videa pro hodnocení, apod."
              data-trigger="hover"
              rel="popover">
        <option value="no-video" label="no-video">no-video</option>
        <option value="oj-team_non-verbal_neil-en" label="oj-team_non-verbal_neil-en">oj-team_non-verbal_neil-en</option>
        <option value="oj-team_pavel-liska" label="oj-team_pavel-liska">oj-team_pavel-liska</option>
        <option value="jakub_kotek_obecne" label="jakub_kotek_obecne">jakub_kotek_obecne</option>
        <option value="steve-watts" label="steve-watts">steve-watts</option>
        <option value="vanocni-videa-cz" label="vanocni-videa-cz">vanocni-videa-cz</option>
        <option value="oj-team_pavel-liska_nejazyk" label="oj-team_pavel-liska_nejazyk">oj-team_pavel-liska_nejazyk</option>
        <option value="oj-team_pavel-liska_neil" label="oj-team_pavel-liska_neil">oj-team_pavel-liska_neil</option>
        <option value="jakub_kotek_AJ" label="jakub_kotek_AJ">jakub_kotek_AJ</option>
        <option value="default" label="default" selected="selected">default</option>
        <option value="oj-team_pavel-liska_neil_EN_solidni" label="oj-team_pavel-liska_neil_EN_solidni">oj-team_pavel-liska_neil_EN_solidni</option>
        <option value="neil_davis" label="neil_davis">neil_davis</option>
        <option value="lucka" label="lucka">lucka</option>
        <option value="test" label="test">test</option>
        <option value="oj-team_non-verbal" label="oj-team_non-verbal">oj-team_non-verbal</option>
        <option value="oj-team_liska_neil_babcakova_pavlasek" label="oj-team_liska_neil_babcakova_pavlasek">oj-team_liska_neil_babcakova_pavlasek</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="hasCertificate" class="control-label optional">Kurz má certifikát</label>
    <div class="controls">
      <input type="hidden" name="hasCertificate" value="0"><input type="checkbox"
                                                                  name="hasCertificate"
                                                                  id="hasCertificate"
                                                                  v-model="co.has_certificate"
                                                                  checked="checked" data-original-title="Kurz má certifikát" data-content="Příznak, zda má kurz certifikát." data-trigger="hover" rel="popover"></div></div>
  <div class="control-group">
    <label for="image" class="control-label optional">Obrázek</label>
    <div class="controls">
      <input type="hidden" name="MAX_FILE_SIZE" value="47185920" id="MAX_FILE_SIZE">
      <input type="file"
             name="image"
             id="image"
             data-original-title="Obrázek kurzu"
             data-content="Motivační obrázek kurzu - například fotka lektora.<br><br>Povolené formáty obrázku jsou: JPG, PNG, GIF.<br><br>Ideální rozměry obrázku pro správné zobrazení jsou 320 x 250px" data-trigger="hover" rel="popover">
    </div>
  </div>
  <div class="control-group">
    <label for="hoverImage" class="control-label optional">Obrázek po najetí myši</label>
    <div class="controls">

      <input type="file"
             name="hoverImage"

             id="hoverImage"
             data-original-title="Obrázek kurzu"
             data-content="Motivační obrázek kurzu - varianta, která se zobrazí po najetí myši.<br><br>Povolené formáty obrázku jsou: JPG, PNG, GIF.<br><br>Ideální rozměry obrázku pro správné zobrazení jsou 320 x 250px"
             data-trigger="hover"
             rel="popover">
    </div>
  </div>
  <div class="control-group"><label for="onlyLbHelpboxes" class="control-label optional">Nezobrazovat defaultní Help boxy.</label>
    <div class="controls">
      <input type="hidden" name="onlyLbHelpboxes" value="0">
      <input type="checkbox"
             name="onlyLbHelpboxes"
             v-model="co.only_lb_helpboxes"
             id="onlyLbHelpboxes"
             value="1"
             data-content="Příznak, zda se budou zobrazovat pouze helpboxy nastavené pro konkrétní slide."
             data-trigger="hover"
             rel="popover"
             data-original-title="">
    </div>
  </div>
  <div class="control-group"><label for="isWeeklyGradual" class="control-label optional">Odemykání po týdnech</label>
    <div class="controls">
      <input type="hidden"
             name="isWeeklyGradual"
             value="0"><input type="checkbox"
                              name="isWeeklyGradual"
                              v-model="co.is_weekly_gradual"
                              id="isWeeklyGradual"
                              value="1" data-content="Při zaškrtnutí začne tento kurz zpřístupňovat studentům slidy po jednotlivých týdnech na základě datumu prvního přístupu do kurzu." data-trigger="hover" rel="popover" data-original-title=""></div></div>

  <div class="control-group"><label for="isGradual" class="control-label optional">Tento kurz odemyká studentům slidy postupně podle dnů v týdnu.</label>
    <div class="controls">
      <input type="hidden" name="isGradual" value="0"><input type="checkbox"
                                                             name="isGradual"
                                                             id="isGradual"
                                                             v-model="co.is_gradual"
                                                             data-content="Při zaškrtnutí začne tento kurz zpřístupňovat studentům slidy po jednotlivých dnech v týdnu na základě datumu prvního přístupu do kurzu."
                                                             data-trigger="hover"
                                                             rel="popover"
                                                             data-original-title="">
    </div>
  </div>

  <div class="control-group"><label for="menu" class="control-label optional">Položky menu</label>
    <div class="controls">
      <select name="menu[]"
              id="menu"
              multiple="multiple"
              v-model="co.menu"
              data-original-title="Menu"
              data-content="Držením klávesy CTRL lze vybrat více položek. Při výběru žádné položky se zobrazí všechny položky menu."
              data-trigger="hover" rel="popover" style="width: 350px; height: 120px">
        <option value="dashboard" label="Nástěnka">Nástěnka</option>
        <option value="vyuka" label="Výuka">Výuka</option>
        <option value="my_vocabulary" label="Moje slovíčka">Moje slovíčka</option>
        <option value="assignments" label="Záložky">Záložky</option>
        <option value="course_detail" label="Obsah kurzu">Obsah kurzu</option>
        <option value="courses" label="Moje kurzy">Moje kurzy</option>
        <option value="settings" label="Nastavení">Nastavení</option>
        <option value="lektor" label="Lektor">Lektor</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="menuRight" class="control-label optional">Položky menu</label>
    <div class="controls">
      <select name="menuRight[]"
              v-model="co.menuRight"
              id="menuRight"
              multiple="multiple" data-original-title="Menu" data-content="Držením klávesy CTRL lze vybrat více položek. Při výběru žádné položky se zobrazí všechny položky menu." data-trigger="hover" rel="popover">
        <option value="COURSE_MENU_RIGHT_INSERT" label="Vložit speciální znak">Vložit speciální znak</option>
        <option value="COURSE_MENU_RIGHT_SPEECH" label="Vyslovovat">Vyslovovat</option>
        <option value="COURSE_MENU_RIGHT_TRANSLATE" label="Přeložit">Přeložit</option>
        <option value="COURSE_MENU_RIGHT_VOCABULARY" label="Vložit do slovíček">Vložit do slovíček</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="lockWeeks" class="control-label optional">Uzamykat cvičení při nízké úspěšnosti v předcházející cvičení</label>
    <div class="controls">
      <input type="hidden" name="lockWeeks" value="0"><input type="checkbox" name="lockWeeks" id="lockWeeks" v-model="lock_weeks"></div></div>
  <div class="control-group"><label for="courseType" class="control-label required">Typ kurzu</label>
    <div class="controls">
      <select name="courseType" id="courseType">
        <option value="COURSE" label="COURSE">COURSE</option>
        <option value="ADAPTIVE" label="ADAPTIVE">ADAPTIVE</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="adaptiveCourseFinishExercises" class="control-label optional">Adaptivní testování - počet cvičení do konce</label>
    <div class="controls">
      <input type="text" name="adaptiveCourseFinishExercises" id="adaptiveCourseFinishExercises" v-model="co.adaptive_course_finish_exercises">
    </div>
  </div>
  <div class="control-group"><label for="adaptiveCourseFinishTime" class="control-label optional">Adaptivní testování - délka trvání testu v sekundách</label>
    <div class="controls">
      <input type="text"
             name="adaptiveCourseFinishTime"
             v-model="co.adaptive_course_finish_time"
             id="adaptiveCourseFinishTime" ></div></div>
  <div class="control-group"><label for="adaptiveCourseShowResult" class="control-label optional">Zobrazit studentovi výsledek adaptivního testování</label>
    <div class="controls">
      <input type="hidden"
             name="adaptiveCourseShowResult"
             value="0"><input type="checkbox"
                              name="adaptiveCourseShowResult"
                              id="adaptiveCourseShowResult"
                              v-model="co.adaptive_course_show_result"
    ></div></div>
  <div class="control-group"><label for="allowRepeatExercise" class="control-label optional">Povolit opakování cvičení</label>
    <div class="controls">
      <input type="hidden" name="allowRepeatExercise" value="0">
      <input type="checkbox" name="allowRepeatExercise"
             id="allowRepeatExercise"
             v-model="co.allow_repeat_exercise"
             checked="checked"
             data-content="Při zaškrtnutí bude studentům dostupné tlačítko OPAKOVAT pro dříve vyplněná cvičení."
             data-trigger="hover"
             rel="popover"
             data-original-title="">
    </div>
  </div>

  <div class="control-group"><label for="showFooter" class="control-label optional">Zobrazit studentovi patičku</label>
    <div class="controls">
      <input type="hidden" name="showFooter" value="0">
      <input type="checkbox" name="showFooter" id="showFooter" v-model="co.show_footer" checked="checked">
    </div>
  </div>

  <div class="control-group"><label for="smartemailingListId" class="control-label optional">Identifikátor smartemailingového seznamu</label>
    <div class="controls">
      <input type="text"
             name="smartemailingListId"
             id="smartemailingListId"
             v-model="co.smartemailing_list_id"
             data-original-title="Identifikátor smartemailingového seznamu"
             data-content="Toto nastavení se vztahuje pro automaticky odemykané kurzy. Identifikátor slouží k identifikaci seznamu, kam se mají zařadit studenti, kteří s kurzem začínají"
             data-trigger="hover"
             rel="popover">
    </div>
  </div>

  <div class="control-group"><label for="vocabularySetting" class="control-label optional">Režim překladů/výkladů slovíček</label>
    <div class="controls">
      <select name="vocabularySetting"
              id="vocabularySetting"
              v-model="co.vocabulary_setting"
              data-original-title="Režim překladů/výkladů slovíček"
              data-content="Toto nastavení umožnuje lektorovi zvolit, zda budou slovíčka vázána na překlad, výklad nebo kombinaci obojího."
              data-trigger="hover"
              rel="popover">
        <option value="TRANSLATE" label="TRANSLATE" selected="selected">TRANSLATE</option>
        <option value="DESCRIPTION" label="DESCRIPTION">DESCRIPTION</option>
        <option value="TRANSLATE_AND_DESCRIPTION" label="TRANSLATE_AND_DESCRIPTION">TRANSLATE_AND_DESCRIPTION</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="vocabularyTraining" class="control-label optional">Procvičování slovíček(pouze kartičky/vše)</label>
    <div class="controls">
      <input type="hidden" name="vocabularyTraining" value="0">
      <input type="checkbox"
             name="vocabularyTraining"
             id="vocabularyTraining"
             v-model="co.vocabulary_training"
             checked="checked"
             data-original-title="Procvičování slovíček(pouze kartičky/vše)"
             data-content="Toto nastavení umožnuje lektorovi zvolit, jak budou slovíčka v kurzu procvičována(Pouze kartičky/Všechny metody)"
             data-trigger="hover" rel="popover">
    </div>
  </div>
  <div class="control-group"><label for="courseLanguages" class="control-label optional">Dostupné jazyky překladu slovíček</label>
    <div class="controls">
      <select name="courseLanguages[]"
              id="courseLanguages"
              v-model="co.course_languages"
              multiple="multiple"
              data-original-title="Dostupné jazyky překladu slovíček"
              data-content="Při stiknutém CTRL můžete zvolit více dostupných překladů slovíček. Př.: V případě kurzu angličtiny pro české studenty natavíme &quot;čeština&quot; (Zdroj slovíčka je anglický, překlad český)."
              data-trigger="hover" rel="popover">

        <option value="1" label="Čeština" selected="selected">Čeština</option>
        <option value="2" label="Angličtina">Angličtina</option>
        <option value="3" label="Němčina">Němčina</option>
        <option value="4" label="Francouzština">Francouzština</option>
        <option value="5" label="Španělština">Španělština</option>
        <option value="6" label="Ruština">Ruština</option>
        <option value="7" label="Italština">Italština</option>
        <option value="8" label="Bulharština">Bulharština</option>
        <option value="9" label="Rumunština">Rumunština</option>
        <option value="10" label="Polština">Polština</option>
        <option value="11" label="Slovenština">Slovenština</option>
      </select>
    </div>
  </div>
  <div class="control-group"><label for="startCourseDescription" class="control-label optional">Informace o odemykání kurzu</label>
    <div class="controls">
        <textarea name="startCourseDescription"
                  id="startCourseDescription"
                  v-model="co.start_course_description"
                  cols="40" rows="8"
                  data-original-title="Informace o odemykání kurzu"
                  data-content="Popis vysvětlující funkci stránky a automaticky odemykan0ho kurzu"
                  data-trigger="hover"
                  rel="popover"
                  class="input-xxlarge"></textarea>
    </div>
  </div>
  <div class="control-group"><label for="startCourseTag" class="control-label optional">ActiveCampaign štítek ke spuštění kurzu (AKADEMIE/časované kurzy - odesláno při kliknutí na "spustit kurz")</label>
    <div class="controls">
      <input type="text"
             name="startCourseTag" id="startCourseTag"
             v-model="co.start_course_tag"
             data-original-title="ActiveCampaign štítek ke spuštění kurzu"
             data-content="AKADEMIE/časované kurzy - odesláno při kliknutí na &quot;spustit kurz&quot;"
             data-trigger="hover"
             rel="popover"
             class="input-xxlarge">
    </div>
  </div>
  <div class="control-group"><label for="courseNameTag" class="control-label optional">ActiveCampaign štítek se jménem kurzu (odeslaný s prvním vstupem do kurzu s prefixem "KURZ *" nebo "TRIAL *")</label>
    <div class="controls">
      <input type="text"
             name="courseNameTag"
             id="courseNameTag"
             v-model="co.course_name_tag"
             data-original-title="ActiveCampaign štítek se jménem kurzu"
             data-content="odeslaný s prvním vstupem do kurzu s prefixem &quot;KURZ *&quot; nebo &quot;TRIAL *&quot;"
             data-trigger="hover" rel="popover" class="input-xxlarge">
    </div>
  </div>
  <div class="control-group"><label for="languageMutation" class="control-label optional">Jazyková mutace</label>
    <div class="controls">
      <select name="languageMutation" id="languageMutation" v-model="co.language_mutation_id" >
        <option value="0" label="-">-</option>
        <option value="1" label="Čeština">Čeština</option>
        <option value="2" label="Angličtina">Angličtina</option>
        <option value="3" label="Němčina">Němčina</option>
        <option value="4" label="Francouzština">Francouzština</option>
        <option value="5" label="Španělština">Španělština</option>
        <option value="6" label="Ruština">Ruština</option>
        <option value="7" label="Italština">Italština</option>
        <option value="8" label="Bulharština">Bulharština</option>
        <option value="9" label="Rumunština">Rumunština</option>
        <option value="10" label="Polština">Polština</option>
        <option value="11" label="Slovenština">Slovenština</option>
      </select>
    </div>
  </div>

  <div class="control-group"><label for="priority" class="control-label optional">Priorita</label>
    <div class="controls">
      <input type="text" name="priority" id="priority" v-model="co.priority">
    </div>
  </div>
  <div class="form-actions-fixed">
    <input type="submit" name="submit" id="submitbutton" value="Uložit" class="btn btn-primary" @click.prevent="updateCourse">

    <div class="ml-5">
      <a href="/oj/courses">seznam kurzu</a><br>


    </div>
  </div>
</template>

<style scoped>

</style>