<script>

import SelectProduct from "@/components/select-product.vue";
import SelectStudent from "@/components/select-student.vue";
export default {
  name: "subscription-form-product",
  components: {  SelectProduct, SelectStudent },
  props: ['subscription', 'product', 'student'],
  data(){
    return {
      sub:{} ,
      _product: this.product

    }
  },
  mounted(){

    this.sub = this.subscription

  },
  watch:{
    'sub.months':  'changeM'
  },
  methods:{

    changeM(){
      if (this._product && this._product.id){
        this.onProductSelect(this._product)
      }
    },
    onProductSelect(selected_product){
      this._product = selected_product
      let inp = document.getElementById('subscription_payments_attributes_0_total')
      //console.log('sel cen', this.sub.months, selected_product)
      let m = parseInt( this.sub.months )
      let price_name = 'price_monthly'
      if (m == 3) price_name = 'price_3months'
      if (m == 6) price_name = 'price_6months'
      if (m == 12) price_name = 'price_yearly'

      let price = selected_product[`${price_name}_czk`]
      if (!price) price = selected_product[`${price_name}_eur`]

      if (price>0 && (inp.value==null || inp.value == '')) {
        inp.value = price
      }
    }
  }
}
</script>

<template>
<ol>
  <li class="string input optional stringish">
    <label for="subscription_months">Délka</label>
    <select name="subscription[months]"
            id="subscription_months"
            v-model="sub.months">
      <option value="1">1 m</option>
      <option value="3">3 m</option>
      <option value="6">pul roku</option>
      <option value="12">rok</option>
    </select>
  </li>



  <li class="string input required stringish">
    <label> Student</label>

      <select-student :name="`subscription[student_id]`"
                      :student="student"></select-student>


  </li>

  <select-product name="subscription[product_id]"
                            :product="product"></select-product>
</ol>
</template>

<style scoped>

</style>