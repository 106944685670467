<script>
import {VueFinalModal} from "vue-final-modal";
import ModalKkMixin from "@/components/conversation-club/modal-kk-mixin.js";
import draggable from 'vuedraggable'
import {toast} from "vue3-toastify";
import axios from "axios";
export default {
  name: "week-sort-order",
  components: {VueFinalModal, draggable},
  props: ['chapter'],
  mixins: [ModalKkMixin],
  methods:{
    onEnd(){
      let ids = this.chapter.weeks.map(w=>w.id)
      axios.put(`/oj/weeks/sort`, {
        ids: ids,
        chapter_id: this.chapter.id
      }).then(r=>{
        toast.success('Pořadí lekcí bylo uloženo')
      })
    }
  }
}
</script>

<template>

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600">
    <div class="modal-header">
      <b>Kapitola</b> {{this.chapter.name}}
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content">



        <draggable v-model="chapter.weeks"
                   tag="transition-group"
                   :component-data="{name:'fade'}"
                    @end="onEnd"
        >
          <template #item="{element}">
            <div class="h3">{{element.name}}</div>
          </template>
        </draggable>



    </div>
  </vue-final-modal>

</template>

<style scoped>
.h3{
  cursor: move;
}
</style>