<script>
import axios from "axios";
import {toast} from "vue3-toastify";

export default {
  name: "ExerciseMixin",
  methods:{

    saveExercise(){
      this.lesson.exercise.data = JSON.stringify(this.lesson.exercise.data_object)

      if (this.lesson.id == null) {

        axios.post(`/oj/lessons`, {
          lesson: this.lesson,
          exercise: this.lesson.exercise,

        }).then(response => {
          toast.success('Uloženo')
        }).catch(e => {
          console.log(e)
          toast.error(e.response.data.errors)
        })
      }else {

        axios.put(`/oj/exercises/${this.lesson.id}`, {
          exercise: this.lesson.exercise,

        }).then(response => {
          toast.success('Uloženo')
        }).catch(e => {
          console.log(e)
          toast.error(e.response.data.errors)
        })
      }

      this.$emit('save', this.d)
    }

  }
}
</script>

<template>

</template>

<style scoped>

</style>