<script>

/* vzor dat d

{
  "solution": "4",
  "hints": "[]",
  "timer": "0",
  "aboveText": "Je čas, abyste si nacvičili správný pravopis nových slov. Poslechněte si nahrávku a napište slova do křížovky.",
  "secondaryAboveText": "",
  "exerciseText": "",
  "belowText": "",
  "tab": "editor-tab",
  "rows": "10",
  "cols": "15",
  "grid": [
    {
      "3": "l",
      "4": "a",
      "5": "w",
      "6": "y",
      "7": "e",
      "8": "r"
    },
    {
      "2": "d",
      "3": "o",
      "4": "c",
      "5": "t",
      "6": "o",
      "7": "r"
    },
    {
      "0": "p",
      "1": "o",
      "2": "l",
      "3": "i",
      "4": "c",
      "5": "e",
      "7": "o",
      "8": "f",
      "9": "f",
      "10": "i",
      "11": "c",
      "12": "e",
      "13": "r"
    },
    {
      "1": "a",
      "2": "c",
      "3": "t",
      "4": "o",
      "5": "r"
    },
    {
      "3": "m",
      "4": "u",
      "5": "s",
      "6": "i",
      "7": "c",
      "8": "i",
      "9": "a",
      "10": "n"
    },
    {
      "2": "m",
      "3": "a",
      "4": "n",
      "5": "a",
      "6": "g",
      "7": "e",
      "8": "r"
    },
    {
      "4": "t",
      "5": "e",
      "6": "a",
      "7": "c",
      "8": "h",
      "9": "e",
      "10": "r"
    },
    {
      "3": "w",
      "4": "a",
      "5": "i",
      "6": "t",
      "7": "e",
      "8": "r"
    },
    [
      "c",
      "l",
      "e",
      "a",
      "n",
      "e",
      "r"
    ],
    {
      "2": "a",
      "3": "c",
      "4": "t",
      "5": "r",
      "6": "e",
      "7": "s",
      "8": "s"
    }
  ],
  "question": [
    "1. právník",
    "2. lékař",
    "3. policista",
    "4. herec",
    "5. hudebník",
    "6. manažer",
    "7. učitel",
    "8. číšník",
    "9. uklízeč",
    "10. herečka",
    "",
    "",
    "",
    "",
    ""
  ]
}

 */


import ExerciseMixin from "./ExerciseMixin.vue";

export default {
  name: "Crossword",
  props: ['lesson'],
  mixins: [ExerciseMixin],
  data() {
    return {
      d: this.lesson.exercise.data_object,
      vgrid: [],
      grid2: [],
      n_rows: parseInt(this.lesson.exercise.data_object.rows),
      n_cols: parseInt(this.lesson.exercise.data_object.cols)
    }
  },
  watch:{
    lesson(){
      this.d = this.lesson.exercise.data_object

      this.n_rows= parseInt(this.d.rows)
      this.n_cols= parseInt(this.d.cols)
      this.refresh_vgrid()
    },
    n_rows(){
      this.d.rows = this.n_rows
      this.refresh_vgrid()

    },
    n_cols(){
      this.d.cols = this.n_cols
      this.refresh_vgrid()
    }
  },
  mounted(){
    this.refresh_vgrid()
  },
  methods: {
    setSol(c){
      this.d.solution = c
    },

    char_in_grid(row, col) {
      if (Array.isArray(this.d.grid[row])) {

        return this.d.grid[row][col]
      } else {
        if (this.d.grid[row] === undefined) {
          return ''
        }
        return this.d.grid[row][col] || ''
      }
    },
    refresh_vgrid() {
      console.log('refreshing vgrid' , this.n_rows, this.n_cols)
      //this.vgrid = []
      for (let i = 0; i < this.n_rows; i++) {
        //this.vgrid.push([])
        if (!this.vgrid[i]) {
          this.vgrid[i] = []
        }
        for (let j = 0; j < this.n_cols; j++) {
          if (this.vgrid[i][j] === undefined || this.vgrid[i][j] === '') {
            this.vgrid[i][j] = this.char_in_grid(i, j)
          }
        }
      }
    },
    saveChanges(){
      //this.d.grid = this.vgrid
      this.grid2=[]
      for (let i = 0; i < this.n_rows; i++) {
        let row={}
        for (let j = 0; j < this.n_cols; j++) {
          if (this.vgrid[i][j] != '') row[j] = this.vgrid[i][j]
        }
        this.grid2.push(row)
      }
      this.d.grid = this.grid2

      this.saveExercise()
    }
  }
}
</script>

<template>
  <div class="well">
    <div class="row-fluid">
      <div class="span6">
        <div class="control-group"><label for="aboveText" class="control-label optional">Text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.aboveText"  name="aboveText" id="aboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Text, který se zobrazí nad cvičením. Měl by obsahovat zadání cvičení, kde studenta informujeme o tom, co od něho ve cvičení požadujeme a jak má postupovat.</p></div></div>
      </div>
      <div class="span6">
        <div class="control-group"><label for="secondaryAboveText" class="control-label optional">Sekundární text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.secondaryAboveText" name="secondaryAboveText" id="secondaryAboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Sekundární text nad cvičením - slouží zejména pro text ve vyučovaném jazyce</p></div></div>
      </div>
    </div>
  </div>


<div id="crossword">





  <div class="d-flex">
    Řádků <select v-model='n_rows'><option :value="i" v-for="i in 15">{{i}}</option></select>
    Sloupců <select v-model="n_cols"><option :value="i" v-for="i in 15">{{i}}</option></select>
  </div>

  <table>
    <thead>
      <tr>
        <td v-for="c in n_cols" class="text-center">
          <a href="#" @click.prevent="setSol(c)">&darr;</a>
        </td>
        <td></td>
      </tr>
    </thead>
    <tbody>

      <tr v-for="i in n_rows">
        <td v-for="c in n_cols" :class="`icell  ${c == parseInt(d.solution) ? 'solution' : ''} `">
           <input type="text" maxlength="1" size="1" v-model="vgrid[i-1][c-1]" v-if="vgrid[i-1]"/>
        </td>
        <td>
          <input type="text" v-model="d.question[i-1]" />
        </td>
      </tr>
    </tbody>
  </table>


  <div class="form-actions">
    <input type="submit" name="submit" id="submit" value="Uložit" class="btn btn btn-primary btn-large" @click.prevent="saveChanges">
  </div>



  <pre>
    d {{d}}

  </pre>
</div>

</template>

<style scoped>


#crossword td.icell {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  width: 40px;
  border: 1px solid #BBBBBB;
  text-align: center;
}
#crossword td {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  vertical-align: middle;
  color: #454545;
}

#crossword td.icell input {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  width: 40px;
  height: 40px;
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  text-align: center;
  color: #454545;
}

#crossword table tbody td.icell.solution {
  background: none repeat scroll 0 0 #FFCC26;
}
</style>