<template>
<div>
  <table class="table table-striped">
      <thead>
      <tr>
        <th>Produkt</th>
        <th>Počet</th>
        <th>Cena s DPH</th>
        <th>Základ osvobozený</th>
        <th>Základ daně</th>
        <th>DPH</th>
        <td></td>
      </tr>
      </thead>
      <tbody>

      <template v-for="sub in subtotals">
        <tr  :key="sub.id" v-if="sub.delete!=1">

          <td>
            <img  class="icoproduct"  :src="get_product(sub.product_id).small_image_url"  v-if="get_product(sub.product_id).small_image_url"/>
            {{ get_product(sub.product_id).name}}</td>
          <td class="numeros1" ><input type="number" class="form-control" v-model="sub.quantity" /></td>
          <td class="numeros"><input type="number" class="form-control" v-model="sub.amount"/></td>
          <td class="numeros"><input type="number" class="form-control"  v-model="sub.exempt"/></td>
          <td class="numeros"><input type="number" class="form-control"  v-model="sub.standard_base"/></td>
          <td class="numeros"><input type="number" class="form-control"  v-model="sub.vat"/></td>
          <td><a href='#' @click.prevent="handleRemoveSubtotal(sub.id)" title="Smazat polozku"><svg class="svg-inline--fa fa-trash fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg></a></td>

          </tr>
      </template>

        <tr v-if="payment.discount_as_item" >
          <td>Sleva  <b>{{payment.discount_code}}</b></td>
          <td>1</td>
          <td>{{payment.discount_amount * -1}}</td>
          <td>0</td>
          <td>{{-(payment.discount_amount - discount_vat() ) }}   </td>
          <td>{{ - discount_vat()  }} </td>
          <td></td>
        </tr>

      </tbody>
    </table>

  <div class="text-right">
    <button class="btn btn-success" @click.prevent="update_subtotals">Uložit změny</button>
  </div>
</div>
</template>

<script>
import axios from "axios";
import {toast} from "vue3-toastify";
export default {
  props:['payment'],
  name: "payment-subtotals",
  data(){
    return ({
      subtotals_to_remove:[],
      subtotals:[]
    })
  },
  mounted() {
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true
    this.subtotals = this.payment.subtotals
    this.subtotals_to_remove=[]
  },
  methods:{
    discount_vat(){
      let vat_percent = 21
      let vs=0
      for (let i in this.subtotals){
        if (this.subtotals[i].vat){
          vs+= parseFloat(this.subtotals[i].vat)
        }
      }

      if (vs==0) return 0

      let discount_amount = this.payment.discount_amount

      let d_vat = discount_amount - (discount_amount / (1 + (vat_percent / 100)))

      return Math.round(d_vat*100)/100
    },

    handleRemoveSubtotal(sub_id){
      if (!confirm('Opravdu smazat ?')) return

      for( var i in this.subtotals){
        if (this.subtotals[i].id == sub_id){
          this.subtotals_to_remove.push(sub_id)
          this.subtotals.splice(i,1)
        }
      }
    },

    get_product(product_id){
      for (var i=0; i< this.payment.products.length; i++){

        if (this.payment.products[i].id == product_id){
          return this.payment.products[i]
        }

      }

      return {}
    },

    update_subtotals(){
      var arr=[]
      for( var i in this.subtotals){
        var sub = this.subtotals[i]
        arr.push({
          id: sub.id,
          amount: sub.amount,
          exempt: sub.exempt,
          standard_base: sub.standard_base,
          vat: sub.vat,
          quantity: sub.quantity
        })
      }

      axios.patch(`/payments/${this.payment.id}`, {
        subtotals: arr,
        subtotals_to_remove: this.subtotals_to_remove
      }).then(response => {
        if (response.data.ee.length==0){
          //this.$toasted.show("Změny jsou uloženy", {type: 'success'}); //Type of the Toast ['success', 'info', 'error']

          toast(`Změny jsou uloženy`, {
          "type": "success",
          "transition": "slide",
            })


        }else{
          //this.$toasted.show("Nějaká chyba", {type: 'error'}); //Type of the Toast ['success', 'info', 'error']
          toast(`Nějaká chyba`, {
            "type": "error",
            "transition": "slide",
          })
        }

        const pr_dom = document.getElementById('payment-report')
        pr_dom.innerHTML = response.data.report

      })

    }
  }
}
</script>

<style scoped>
td.numeros1 {max-width:80px}
td.numeros {width:17%}
</style>