<template>
  <div>
    <Editor
      api-key="s033ao5dcl8b8qw7j6bhqodmsremp6va27oijf0cv3svx5lf"
      v-model="content"
      :init="editorConfig"
      @input="updateValue"
    />
  </div>
  <edko-file-picker ref="edko_file_picker"></edko-file-picker>
</template>

<script>
import { ref, watch, defineComponent , getCurrentInstance} from 'vue';
import Editor from '@tinymce/tinymce-vue';
import EdkoFilePicker from "./edko-file-picker.vue";
export default defineComponent({
  name: 'CustomEditor',
  components: { Editor , EdkoFilePicker},
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const content = ref(props.modelValue);
    const instance = getCurrentInstance();

    const editorConfig = {
      plugins: [
          'advlist', 'anchor', 'autolink', 'codesample', 'fullscreen', 'help',
        'image',  'tinydrive', 'lists', 'link', 'media',  'preview',
        'searchreplace', 'table',  'visualblocks', 'wordcount'
      ],
      toolbar: 'bold underline italic blue red orange green alternate removeFormat | undo redo | alignleft aligncenter alignright alignjustify | bullist numlist | link image',
      setup: (editor) => {
        editor.ui.registry.addButton('orange', {
          text: 'O',
          title: 'Oranžová',
          onAction: () => {
            editor.focus();
            editor.selection.setContent(`<span class='orange strong'>` + editor.selection.getContent() + '</span>');
          }
        });

        editor.ui.registry.addButton('blue', {
          text: 'M',
          title: 'Modrá',
          onAction: () => {
            editor.focus();
            editor.selection.setContent(`<span class='blue strong'>` + editor.selection.getContent() + '</span>');
          }
        });

        editor.ui.registry.addButton('red', {
          text: 'Č',
          title: 'Červená',
          onAction: () => {
            editor.focus();
            editor.selection.setContent(`<span class='red strong'>` + editor.selection.getContent() + '</span>');
          }
        });

        editor.ui.registry.addButton('green', {
          text: 'Z',
          title: 'Zelená',
          onAction: () => {
            editor.focus();
            editor.selection.setContent(`<span class='green strong'>` + editor.selection.getContent() + '</span>');
          }
        });

        editor.ui.registry.addButton('alternate', {
          text: 'Š',
          title: 'Šedá',
          onAction: () => {
            editor.focus();
            editor.selection.setContent(`<span class='alternate'>` + editor.selection.getContent() + '</span>');
          }
        });
      },
      file_picker_callback: (cb, value, meta) => {
        console.log(' file_picker_callback', cb, value, meta);

        console.log('instance', instance.refs);
        instance.refs.edko_file_picker.open(cb, value, meta);


      },
      content_css: '/wswg.css',
      body_class: 'wswg',
    };

    const updateValue = () => {
      emit('input', content.value);
    };

    watch(() => props.modelValue, (newValue) => {
      content.value = newValue;
    });

    watch(content, (newContent) => {

      emit('update:modelValue', newContent);
    });

    return {
      content,
      editorConfig,
      updateValue
    };
  }
});
</script>

<style>


</style>