<script>
import axios from 'axios';
import ModalKkMixin from '@/components/conversation-club/modal-kk-mixin.js';


export default {
  mixins: [ModalKkMixin],
  name: "edko-file-picker",
  data(){
    return {
      files:[]
    }
  },
  methods:{
    open(cb, value, meta){
      console.log('open',cb, value, meta)
      this.showM = true
      this.loadFiles()
    },

    loadFiles(){
      axios.get('/oj/uploads/ls.json').then(r=>{
        this.files = r.data.files
      })
    },
  }
}
</script>

<template>
    <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600 zuperz">
      <div class="modal-header">
        <b>Soubory a data</b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


      <div>
        <pre>
          {{files}}
        </pre>

      </div>

    </vue-final-modal>
</template>

<style>


</style>