<script>
import axios from 'axios'
import {toast} from "vue3-toastify";
export default {
  name: "exercise-options",
  props:['lesson'],
  data(){
    return {
    }
  },
  methods:{
    saveChanges(){
      console.log('saveChanges')
      if (this.lesson.id) {
        axios.put(`/oj/lessons/${this.lesson.id}`, {
          lesson: this.lesson
        }).then(response => {
          toast.success('Uloženo')
        }).catch(e => {
          console.log(e)
          toast.error(e.response.data.errors)
        })
      }else {
        axios.post(`/oj/lessons`, {
          lesson: this.lesson,
          exercise: this.lesson.exercise,
          data_object: this.lesson.exercise.data_object
        }).then(response => {
          toast.success('Uloženo')
        }).catch(e => {
          console.log(e)
          toast.error(e.response.data.errors)
        })
      }
    }
  }
}
</script>

<template>
  <div>

      <div class="control-group">
        <label for="name" class="control-label required">Název cvičení</label>
        <div class="controls">
          <input type="text" name="name" id="name" v-model="lesson.name" class="input-xxlarge">
          <p class="help-block" style="color: #999;">Stručný název cvičení.</p>
        </div>
      </div>

      <div class="control-group">
        <label for="secondary_name" class="control-label optional">Sekundární název cvičení</label>
        <div class="controls">
          <input type="text" name="secondary_name" id="secondary_name" v-model="lesson.secondary_name" class="input-xxlarge">
          <p class="help-block" style="color: #999;">Sekundární název - slouží zejména pro název ve vyučovaném jazyce</p>
        </div>
      </div>


      <div class="control-group">
        <label for="level" class="control-label optional">Úroveň obtížnosti cvičení</label>
        <div class="controls">
          <select name="level" id="level" v-model="lesson.exercise.level">
            <option value="EXERCISE_LEVEL_A1" label="LEVEL_A1">LEVEL_A1</option>
            <option value="EXERCISE_LEVEL_A2" label="LEVEL_A2">LEVEL_A2</option>
            <option value="EXERCISE_LEVEL_LEVEL_B1M" label="LEVEL_B1M">LEVEL_B1M</option>
            <option value="EXERCISE_LEVEL_LEVEL_B1P" label="LEVEL_B1P">LEVEL_B1P</option>
            <option value="EXERCISE_LEVEL_LEVEL_B1" label="LEVEL_B1">LEVEL_B1</option>
            <option value="EXERCISE_LEVEL_LEVEL_B2M" label="LEVEL_B2M">LEVEL_B2M</option>
            <option value="EXERCISE_LEVEL_LEVEL_B2P" label="LEVEL_B2P">LEVEL_B2P</option>
            <option value="EXERCISE_LEVEL_LEVEL_B2" label="LEVEL_B2">LEVEL_B2</option>
            <option value="EXERCISE_LEVEL_LEVEL_C1" label="LEVEL_C1">LEVEL_C1</option>
            <option value="EXERCISE_LEVEL_LEVEL_C2" label="LEVEL_C2">LEVEL_C2</option>
          </select>
          <p class="help-block" style="color: #999;">Určuje úroveň obtížnosti cvičení. Tento údaj je hlavně pro filtraci.</p>
        </div>
      </div>

      <div class="control-group">
        <label for="category" class="control-label optional">Kategorie cvičení</label>
        <div class="controls">
          <select name="category" id="category" v-model="lesson.exercise.category">
            <option value="EXERCISE_CATEGORY_GRAMMAR" label="EXERCISE_CATEGORY_GRAMMAR">EXERCISE_CATEGORY_GRAMMAR</option>
            <option value="EXERCISE_CATEGORY_VOCABULARY" label="EXERCISE_CATEGORY_VOCABULARY">EXERCISE_CATEGORY_VOCABULARY</option>
          </select>
          <p class="help-block" style="color: #999;">Kategorizaci cvičení. Zobrazuje se poté ve výpisu všech cvičení a také se podle něj filtruje.</p>
        </div>
      </div>

      <div class="control-group">
        <label for="description" class="control-label optional">Krátká charakteristika cvičení</label>
        <div class="controls">
          <textarea name="description" id="description" cols="40" rows="8" class="input-xxlarge" v-model="lesson.exercise.description"></textarea>
          <p class="help-block" style="color: #999;">Popis cvičení. Zobrazuje se poté ve výpisu všech cvičení.</p>
        </div>
      </div>

<!--
      <div class="control-group">
        <label for="avatar_text" class="control-label optional">Automatický poslech</label>
        <div class="controls">
          <div class="alert alert-danger">
            <div>Každý poslech unikátního textu spotřebuje určité množství předplacených kreditů z externí služby iSpeech.
              Z tohoto důvodu prosím, abyste pro ladění textu pro automatický poslech využívaly přímo
              stránku <a href="https://www.ispeech.org/text.to.speech" target="_blank">iSpeech.org</a>.</div>
            <div>Podporované hlasy:<ul><li>US English - Female</li><li>European German - Female</li>
              <li>European French - Female</li><li>US Spanish - Female</li><li>European Czech - Female</li><li>Russian - Female</li>
              <li>European Italian - Male</li></ul></div><div>Počet použitých slov <span id="avatar-text-word-limit">?/180</span></div>
          </div><textarea name="avatar_text" id="avatar_text" cols="40" rows="8" class="input-xxlarge"></textarea>

          <p class="help-block" style="color: #999;">Text který se automaticky přečte na stránce s cvičením.</p>
        </div>
      </div>

      <div class="control-group"><label for="avatar_text_mode" class="control-label optional">Způsob automatického čtení textu</label>
        <div class="controls">
          <select name="avatar_text_mode" id="avatar_text_mode">
            <option value="AVATAR_TEXT_MODE_IMMEDIATELY" label="AVATAR_TEXT_MODE_IMMEDIATELY">AVATAR_TEXT_MODE_IMMEDIATELY</option>
            <option value="AVATAR_TEXT_MODE_IMMEDIATELY_WITHOUT_TEXT" label="AVATAR_TEXT_MODE_IMMEDIATELY_WITHOUT_TEXT">AVATAR_TEXT_MODE_IMMEDIATELY_WITHOUT_TEXT</option>
            <option value="AVATAR_TEXT_MODE_IMMEDIATELY_WITHOUT_TEXT_WITH_BUTTON" label="AVATAR_TEXT_MODE_IMMEDIATELY_WITHOUT_TEXT_WITH_BUTTON">AVATAR_TEXT_MODE_IMMEDIATELY_WITHOUT_TEXT_WITH_BUTTON</option>
            <option value="AVATAR_TEXT_MODE_ONDEMAND" label="AVATAR_TEXT_MODE_ONDEMAND">AVATAR_TEXT_MODE_ONDEMAND</option>
            <option value="AVATAR_TEXT_MODE_ONDEMAND_WITHOUT_TEXT" label="AVATAR_TEXT_MODE_ONDEMAND_WITHOUT_TEXT" selected="selected">AVATAR_TEXT_MODE_ONDEMAND_WITHOUT_TEXT</option>
          </select>
        </div>
      </div>
     -->


      <div class="control-group">
        <label for="transcript" class="control-label optional">Přepis nahrávky</label>
        <div class="controls">
          <textarea name="transcript" id="transcript" cols="40" rows="8" class="input-xxlarge" v-model="lesson.transcript"></textarea>
          <p class="help-block" style="color: #999;">Text, který si může student přečíst, když například nerozumí nahrávce</p>
        </div>
      </div>


      <div class="control-group"><label for="course_type" class="control-label optional">Pro jaký typ kurzu je cvičení určeno?</label>
        <div class="controls">
          <select name="course_type" id="course_type" v-model="lesson.exercise.course_type">
            <option value="CZECH" label="CZECH">CZECH</option>
            <option value="ENGLISH" label="ENGLISH">ENGLISH</option>
            <option value="GERMAN" label="GERMAN">GERMAN</option>
            <option value="FRENCH" label="FRENCH">FRENCH</option>
            <option value="SPANISH" label="SPANISH">SPANISH</option>
            <option value="RUSSIAN" label="RUSSIAN">RUSSIAN</option>
            <option value="ITALIAN" label="ITALIAN">ITALIAN</option>
            <option value="ROMANIAN" label="ROMANIAN">ROMANIAN</option>
            <option value="BULGARIAN" label="BULGARIAN">BULGARIAN</option>
            <option value="POLISH" label="POLISH">POLISH</option>
            <option value="SLOVAK" label="SLOVAK">SLOVAK</option>
          </select>
          <p class="help-block" style="color: #999;">Určuje jazyk/typ kurzu cvičení.</p>
        </div>
      </div>
      <div class="control-group">
        <label for="course_level" class="control-label optional">Pro jakou úroveň kursu je cvičení určeno?</label>
        <div class="controls">
          <select name="course_level" id="course_level" v-model="lesson.exercise.course_level">
            <option value="COURSE_LEVEL_A1" label="LEVEL_A1" selected="selected">LEVEL_A1</option>
            <option value="COURSE_LEVEL_A2" label="LEVEL_A2">LEVEL_A2</option>
            <option value="COURSE_LEVEL_B1M" label="LEVEL_B1M">LEVEL_B1M</option>
            <option value="COURSE_LEVEL_B1P" label="LEVEL_B1P">LEVEL_B1P</option>
            <option value="COURSE_LEVEL_B1" label="LEVEL_B1">LEVEL_B1</option>
            <option value="COURSE_LEVEL_B2M" label="LEVEL_B2M">LEVEL_B2M</option>
            <option value="COURSE_LEVEL_B2P" label="LEVEL_B2P">LEVEL_B2P</option>
            <option value="COURSE_LEVEL_B2" label="LEVEL_B2">LEVEL_B2</option>
            <option value="COURSE_LEVEL_C1" label="LEVEL_C1">LEVEL_C1</option>
            <option value="COURSE_LEVEL_C2" label="LEVEL_C2">LEVEL_C2</option>
          </select>
          <p class="help-block" style="color: #999;">Podle tohoto se filtrují cvičení poté ve výpise kurzu.</p>
        </div>
      </div>

    <div class="control-group">
      <label for="is_active" class="control-label optional">Je cvičení určeno pro použití?</label>
        <div class="controls">
          <input type="hidden" name="is_active" value="0">
          <input type="checkbox" name="is_active" id="is_active" v-model="lesson.is_active" value="1" checked="checked">
          <p class="help-block" style="color: #999;">Určuje, zda bude cvičení zobrazeno ve výpisu kurzu.</p>
        </div>
    </div>

      <div class="control-group">
        <label class="control-label optional">MP3</label>
        <div class="controls">
          <table id="mp3-list" class="table table-striped table-bordered table-condensed hi dden">
            <thead>
            <tr>
              <th>Soubor</th>
              <th>Popisek</th>
              <th>Text</th>
              <th>Spustit automaticky</th>
              <th>Akce</th>
            </tr>
            </thead>
            <tbody><tr class="hidden">
              <td><a href="/wizard/get-file/id/__lessonFileId">__lessonFileName</a></td>
              <td>
                <input type="text" name="mp3_label[__lessonFileId]" disabled="disabled">
                <input type="hidden" name="mp3[__lessonFileId]" value="__lessonFileId" disabled="disabled">
              </td>
              <td>
                <textarea rows="4" cols="30" name="mp3_text[__lessonFileId]" disabled="disabled"></textarea>
              </td>
              <td>
                <input type="hidden" value="0" name="mp3_autoplay[__lessonFileId]">
                <input type="checkbox" name="mp3_autoplay[__lessonFileId]" value="1">
              </td>
              <td>
                <a class="mp3-remove-btn" href="/wizard/remove-file/id/__lessonFileId">Smazat</a>
              </td>
            </tr>

            </tbody>
          </table>
          <div id="mp3-container" style="position: relative;">
            <input id="mp3-up-btn" type="button" value="Nahrát soubor" style="position: relative; z-index: 0;">
            <span id="mp3-progress"></span>
            <div id="p1iabavepn1g1qt7p1tga9q31n9f0_html5_container" style="position: absolute; background: transparent; width: 101px; height: 26px; overflow: hidden; z-index: -1; opacity: 0; top: 0px; left: 0px;" class="plupload html5"><input id="p1iabavepn1g1qt7p1tga9q31n9f0_html5" style="font-size: 999px; position: absolute; width: 100%; height: 100%;" type="file" accept="audio/mpeg" multiple="multiple"></div></div>
          <span class="help-block">Povolené přípony: mp3</span>
        </div>
      </div>

      <div class="control-group">
        <label for="evaluate" class="control-label optional">Bude se cvičení vyhodnocovat?</label>
        <div class="controls">
          <input type="hidden" name="evaluate" value="0">
          <input type="checkbox" name="evaluate" id="evaluate" value="1" checked="checked" v-model="lesson.evaluate">
        </div>
      </div>

      <div class="control-group">
        <label for="isMandatory" class="control-label optional">Jedná se o povinné cvičení?</label>
        <div class="controls">
          <input type="hidden" name="isMandatory" value="0">
          <input type="checkbox" name="isMandatory" id="isMandatory" value="1" checked="checked"
                 v-model="lesson.exercise.is_mandatory"
                 data-original-title="Povinné cvičení"
                 data-content="Nepovinná cvičení se nepočítají do celkové úspěšnosti kurzu."
                 data-trigger="hover" rel="popover">
        </div>
      </div>

      <div class="control-group">
        <label for="trialOnly" class="control-label optional">Je obsah určen pouze pro TRIAL kurzu?</label>
        <div class="controls">
          <input type="hidden" name="trialOnly" value="0">
          <input type="checkbox" name="trialOnly" id="trialOnly" value="1" v-model="lesson.trial_only">
          <p class="help-block" style="color: #999;">Obsah bude zobrazen pouze v trialu kurzu.</p>
        </div>
      </div>

      <div class="control-group"><label for="isNew" class="control-label optional">Cvičení je nové</label>
        <div class="controls">
          <input type="hidden" name="isNew" value="0">
          <input type="checkbox" name="isNew" id="isNew"  v-model="lesson.is_new"  checked="checked">
          <p class="help-block" style="color: #999;">Pokud je cvičení označeno jako nové, tak bude označeno štítkem i ve studovně.</p>
        </div>
      </div>

      <div class="control-group">
        <label for="acTag" class="control-label optional">ActiveCampaing TAG</label>
        <div class="controls">
          <input type="text" name="acTag" id="acTag" v-model="lesson.ac_tag"  class="input-xxlarge">
          <p class="help-block" style="color: #999;">Vyplňte název tagu, který bude do Active Campaign odeslán při navštívení této obsahové stránky</p>
        </div>
      </div>

      <div class="control-group">
        <label for="orderInWeek" class="control-label optional">Pořadí v týdnu</label>
        <div class="controls">
          <input type="text" name="orderInWeek" id="orderInWeek" value="0" class="input-xxlarge" v-model="lesson.order_in_week">
          <p class="help-block" style="color: #999;">Pořadí cvičení v týdnu (1 - pondělí, 2 - úterý, ...). Používá se pro postupně odemykající se kurz</p>
        </div>
      </div>
<!--
      <div class="control-group"><label for="milestone" class="control-label optional">Milník</label>
        <div class="controls">
          <input type="text" name="milestone" id="milestone" v-model="lesson.milestone" class="input-xxlarge" >
          <p class="help-block" style="color: #999;">Pořadové číslo milníku v rámci kurzu.</p>
        </div>
      </div>

      <div class="control-group"><label for="milestoneText" class="control-label optional">Popis milníku</label>
        <div class="controls">
          <textarea name="milestoneText" id="milestoneText"
                    cols="40" rows="4"
                    class="input-xxlarge"
                    v-model="lesson.milestone_text"></textarea>
          <p class="help-block" style="color: #999;">Orientační popis milníku.</p>
        </div>
      </div>

      <input type="hidden" name="tab" value="info-tab" id="tab">
      <div class="control-group">
        <label for="startDate" class="control-label optional">Zobrazit od (YYYY-MM-DD HH:MM:SS)</label>
        <div class="controls">
          <input type="text" name="startDate" id="startDate" value="1900-01-01 00:00:00" class="input-xxlarge">
        </div>
      </div>
          -->

    <div class="form-actions">
        <input type="submit"
               name="submit"
               id="submitbutton"
               value="Uložit"
               class="btn btn btn-primary"
               @click.prevent="saveChanges"
        />
    </div>




  </div>
</template>

<style scoped>

</style>